@import url(https://fonts.googleapis.com/css?family=Oxygen:300,400,700|Roboto:300,400,500,700);
.bold {
  font-weight: bold; }

.leftAlign {
  text-align: left; }

.greyText {
  color: #868C8F; }

.whiteText {
  color: #FFFFFF !important; }

.orangeBg {
  background: -webkit-linear-gradient(top, #00aeff 0%, #0091ff 100%) !important;
  background: linear-gradient(180deg, #00aeff 0%, #0091ff 100%) !important; }

.hidden {
  display: none !important; }

.rightAlign {
  width: 100%;
  text-align: right; }

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%; }

a {
  color: rgba(0, 0, 0, 0.87); }

a:hover {
  color: #00aeff; }

.modals.dimmer[class*="top aligned"] .modal {
  margin: 65px auto 0px auto !important; }

.greyText {
  color: #868C8F; }

.whiteText {
  color: #FFFFFF !important; }

.orangeBg {
  background: -webkit-linear-gradient(top, #00aeff 0%, #0091ff 100%) !important;
  background: linear-gradient(180deg, #00aeff 0%, #0091ff 100%) !important; }

.hidden {
  display: none !important; }

.bold {
  font-weight: bold; }

.leftAlign {
  text-align: left; }

.site-logo {
  height: 55px;
  margin-left: 10px;
  display: inline-block; }

.nav-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  z-index: 9998;
  background: #fff; }

nav.top-nav {
  padding: 11px 10px 9px 10px;
  display: inline-block;
  vertical-align: top; }

div.gradient-bar {
  height: 0px;
  width: 100%;
  background: #bbb; }

.sos-alert-box {
  background-color: #ff5668;
  border-radius: 5px;
  padding: 10px;
  width: 287px;
  position: absolute;
  right: 10px;
  top: 73px;
  z-index: 9999; }
  .sos-alert-box .sos-triggered {
    color: white; }
  .sos-alert-box p {
    color: white; }
  .sos-alert-box .dismiss-sos-btn {
    width: 80px;
    border: 0;
    background: none;
    border: 1px white solid;
    border-radius: 5px;
    color: white;
    margin-right: 10px; }

.user-details {
  margin-right: 20px;
  float: right; }

.login-status, .user-name {
  padding: 20px 0px;
  display: inline-block;
  cursor: pointer; }

.user-name {
  font-weight: bold; }

.user-image {
  border: #00aeff 2px solid;
  border-radius: 50%;
  height: 40px;
  margin-left: 5px;
  padding: 5px 12px; }

.logout-icon {
  margin-left: 5px !important; }

#user-dropdown {
  width: 150px; }

.nav-item {
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
  margin: 0 30px;
  color: #0091ff;
  vertical-align: middle; }
  .nav-item .nav-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    position: relative;
    top: 4px; }
  .nav-item .nav-label {
    display: inline-block;
    line-height: 40px;
    margin: 0px; }

.nav-item.active .nav-icon {
  fill: #00aeff; }

.nav-item.active .nav-label {
  color: #00aeff; }

.nav-item:hover .nav-icon {
  fill: #fff; }

.nav-item:hover .nav-label {
  color: #868C8F; }

div.side-menu {
  min-width: 76px;
  max-width: 76px;
  background-color: #363B4B;
  overflow-x: hidden;
  text-align: center;
  font-size: 12px;
  position: fixed;
  top: 61px;
  bottom: 0px;
  z-index: 1000; }

div.side-menu li {
  list-style-type: none;
  margin-top: 25px; }

div.side-menu li a .icon {
  margin-bottom: 5px; }

div.side-menu li a .icon, div.side-menu li a {
  color: #868C8F; }

div.side-menu li:hover a .icon {
  color: #FFF; }

div.side-menu li a.active .icon {
  color: #00aeff; }

div.side-menu li a.active, div.side-menu li:hover a {
  color: #FFF; }

.mapDatePicker {
  font-size: 11px !important;
  float: right;
  width: 48%;
  margin-right: 2%; }

.mapDatePicker input {
  width: 100%; }

.MapTimeFiltersContainer {
  position: relative;
  bottom: 0px; }

button.mapDatePickerButton {
  float: right;
  width: 30%;
  height: 32px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  border: none;
  margin: 10px 5px;
  background: #db2828; }

button.searchByArea {
  margin: 10px 5px !important; }

.top-submenu {
  border-bottom: 1px solid #e8e8e8;
  padding: 12px 75px;
  vertical-align: middle; }

.top-submenu-button {
  display: inline;
  margin-right: 100px; }
  .top-submenu-button .top-submenu-link {
    display: inline;
    color: #c1c1c1; }
  .top-submenu-button .top-submenu-link-active {
    color: #00aeff; }
  .top-submenu-button .top-submenu-icon {
    display: inline;
    font-size: 18px;
    vertical-align: middle;
    margin-right: 5px; }
  .top-submenu-button .top-submenu-label {
    display: inline;
    vertical-align: middle; }

div.sub-menu {
  width: 300px;
  background-color: #FFF;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%; }

div.sub-menu .ui.list {
  padding: 0px 15px; }

div.sub-menu .ui.list .item {
  margin: 0px 0px 10px 0px; }

div.sub-menu .ui.list .header {
  font-size: 13px;
  margin-bottom: 0;
  font-weight: 400; }

div.sub-menu .ui.list .description {
  font-size: 11px; }

div.sub-menu .ui.list .item:hover .close {
  visibility: visible; }

div.sub-menu .ui.list .item .close {
  visibility: hidden;
  padding-left: 20px; }

div.subCategorySelect {
  padding: 0px 0px 5px 30px !important; }

div.subCategorySelect label {
  font-size: 11px !important; }

.menuSplitter {
  margin: 20px 0px; }

div.sidemenu-button {
  margin: 40px 0; }
  div.sidemenu-button a.sidemenu-item {
    color: #8B8F9A;
    text-decoration: none;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px; }
    div.sidemenu-button a.sidemenu-item i.sidemenu-icon {
      margin: 0;
      font-size: 21px; }

a.sidemenu-item.sidemenu-active i.sidemenu-icon {
  color: #00aeff; }

a.sidemenu-item.sidemenu-active p.sidemenu-label {
  color: white; }

div.sidemenu-button:hover a.sidemenu-item:hover {
  color: white; }
  div.sidemenu-button:hover a.sidemenu-item:hover i.sidemenu-icon {
    color: white; }

a.sidemenu-item.sidemenu-active:hover i.sidemenu-icon {
  color: #00aeff; }

a.sidemenu-item.sidemenu-active:hover p.sidemenu-label {
  color: white; }

div.accordion-container {
  width: 300px; }
  div.accordion-container div.accordion-item {
    border-bottom: 1px #eaeaea solid; }
    div.accordion-container div.accordion-item div.accordion-title {
      width: 300px;
      cursor: pointer;
      color: #404040;
      background: #f4f4f4;
      padding: 9px 15px;
      font-size: 12px;
      font-weight: bold; }
      div.accordion-container div.accordion-item div.accordion-title.active {
        background: -webkit-linear-gradient(top, #00aeff 0%, #0091ff 100%);
        background: linear-gradient(180deg, #00aeff 0%, #0091ff 100%);
        color: white; }
    div.accordion-container div.accordion-item div.accordion-content {
      width: 300px;
      background: white;
      height: 0;
      padding: 0;
      overflow: hidden;
      -webkit-transition: height 0.35s;
      transition: height 0.35s; }
      div.accordion-container div.accordion-item div.accordion-content.active {
        height: calc(100vh - 160px);
        overflow-y: scroll; }
      div.accordion-container div.accordion-item div.accordion-content div.accordion-content-container {
        padding: 10px 15px; }

div.accordion-container-2 {
  width: 300px; }
  div.accordion-container-2 div.accordion-item {
    border-bottom: 1px #eaeaea solid; }
    div.accordion-container-2 div.accordion-item div.accordion-title {
      width: 300px;
      cursor: pointer;
      color: #404040;
      background: #f4f4f4;
      padding: 9px 15px;
      font-size: 12px;
      font-weight: bold; }
      div.accordion-container-2 div.accordion-item div.accordion-title.active {
        background: -webkit-linear-gradient(top, #00aeff 0%, #0091ff 100%);
        background: linear-gradient(180deg, #00aeff 0%, #0091ff 100%);
        color: white; }
    div.accordion-container-2 div.accordion-item div.accordion-content {
      width: 300px;
      background: white;
      height: 0;
      padding: 0;
      overflow: hidden;
      -webkit-transition: height 0.35s;
      transition: height 0.35s; }
      div.accordion-container-2 div.accordion-item div.accordion-content.active {
        height: calc(100vh - 125px);
        overflow-y: scroll; }
      div.accordion-container-2 div.accordion-item div.accordion-content div.accordion-content-container {
        padding: 10px 15px; }

div.accordion-container-5 {
  width: 300px; }
  div.accordion-container-5 div.accordion-item {
    border-bottom: 1px #eaeaea solid; }
    div.accordion-container-5 div.accordion-item div.accordion-title {
      width: 300px;
      cursor: pointer;
      color: #404040;
      background: #f4f4f4;
      padding: 9px 15px;
      font-size: 12px;
      font-weight: bold; }
      div.accordion-container-5 div.accordion-item div.accordion-title.active {
        background: -webkit-linear-gradient(top, #00aeff 0%, #0091ff 100%);
        background: linear-gradient(180deg, #00aeff 0%, #0091ff 100%);
        color: white; }
    div.accordion-container-5 div.accordion-item div.accordion-content {
      width: 300px;
      background: white;
      height: 0;
      padding: 0;
      overflow: hidden;
      -webkit-transition: height 0.35s;
      transition: height 0.35s; }
      div.accordion-container-5 div.accordion-item div.accordion-content.active {
        height: calc(100vh - 220px);
        overflow-y: scroll; }
      div.accordion-container-5 div.accordion-item div.accordion-content div.accordion-content-container {
        padding: 10px 15px; }

ul.tracking-user-results {
  margin: 0;
  padding: 0;
  list-style: none; }
  ul.tracking-user-results div.tracking-user-result-item {
    margin-bottom: 10px; }
    ul.tracking-user-results div.tracking-user-result-item div.result-checkbox {
      display: inline;
      vertical-align: middle; }
    ul.tracking-user-results div.tracking-user-result-item p.result-name {
      font-size: 13px;
      margin-left: 25px;
      margin-bottom: 0; }
    ul.tracking-user-results div.tracking-user-result-item p.result-details {
      margin-left: 25px;
      font-size: 11px; }
      ul.tracking-user-results div.tracking-user-result-item p.result-details span.details-link {
        color: #0082AF; }

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #00aeff !important; }

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #00aeff !important; }

.ui.horizontal.list:not(.celled) > .item:first-child {
  padding: 5px 15px 5px 15px !important; }

.ui.divided.horizontal.list > .item {
  border: 1px solid #6244e0 !important;
  border-radius: 5px;
  margin-left: 20px;
  padding: 5px 15px 5px 15px !important; }

.emailRecipient {
  color: #6244e0; }

.emailRecipient .header {
  color: #6244e0 !important; }

.emailRecipientGroup {
  background: #6244e0; }

.emailRecipientGroup .header {
  background: #6244e0;
  color: #FFFFFF !important; }

#email-form-message {
  min-height: 350px; }

.emailTemplatesContainer {
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: .28571429rem;
  height: 427px;
  padding: .78571429em 1em;
  margin-top: 3px;
  overflow-y: scroll;
  overflow-x: hidden; }

.emailTemplatesLabel {
  font-weight: 700;
  font-size: .92857143em; }

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #00aeff !important; }

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #00aeff !important; }

.ui.horizontal.list:not(.celled) > .item:first-child {
  padding: 5px 15px 5px 15px !important; }

.ui.divided.horizontal.list > .item {
  border: 1px solid #6244e0 !important;
  border-radius: 5px;
  margin-left: 20px;
  padding: 5px 15px 5px 15px !important; }

.pushRecipient {
  color: #6244e0; }

.pushRecipient .header {
  color: #6244e0 !important; }

.pushRecipientGroup {
  background: #6244e0; }

.pushRecipientGroup .header {
  background: #6244e0;
  color: #FFFFFF !important; }

#sms-form-message,
#push-form-message {
  min-height: 100px; }

.pushTemplatesContainer {
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: .28571429rem;
  height: 100px;
  padding: .78571429em 1em;
  margin-top: 3px;
  overflow-y: scroll;
  overflow-x: hidden; }

.pushTemplatesLabel {
  font-weight: 700;
  font-size: .92857143em; }

button.primary {
  height: 42px;
  width: 280px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  border: none;
  margin: 10px;
  display: inline-block;
  background: -webkit-linear-gradient(top, #00aeff 0%, #0091ff 100%);
  background: linear-gradient(180deg, #00aeff 0%, #0091ff 100%); }

button.primaryAlertInfoBox {
  height: 42px;
  width: 90%;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  border: none;
  margin: 10px;
  display: inline-block;
  background: -webkit-linear-gradient(top, #00aeff 0%, #0091ff 100%);
  background: linear-gradient(180deg, #00aeff 0%, #0091ff 100%); }

button.secondary {
  border: 1px #00aeff solid;
  height: 42px;
  width: 280px;
  background: white;
  margin: 10px;
  border-radius: 5px;
  color: #00aeff;
  display: inline-block;
  font-size: 14px; }

button.grey {
  height: 42px;
  width: 280px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  border: none;
  margin: 10px;
  display: inline-block;
  background: #868C8F; }

.primaryButton {
  padding: 10px;
  width: 280px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  border: none;
  margin: 10px;
  display: inline-block;
  background: -webkit-linear-gradient(top, #00aeff 0%, #0091ff 100%);
  background: linear-gradient(180deg, #00aeff 0%, #0091ff 100%);
  text-align: center; }

.primaryButton:hover {
  color: white;
  opacity: 0.8; }

.delete {
  float: left;
  width: 100%;
  height: 32px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  border: none;
  margin: 10px 5px;
  background: #db2828; }

.editcta {
  float: left;
  width: 100%;
  height: 32px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  border: none;
  margin: 10px 5px;
  background: green; }

.viewcta {
  float: left;
  width: 140px;
  height: 32px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  border: none;
  margin: 10px 5px;
  background: green; }

.closeCTA {
  float: left;
  width: 80px;
  height: 32px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  border: none;
  margin: 10px 5px;
  background: orange; }

.approve {
  float: right;
  width: 100px;
  height: 32px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  border: none;
  margin: 10px 5px;
  background: green; }

.reject {
  float: right;
  width: 100px;
  height: 32px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  border: none;
  margin: 10px 5px;
  background: red; }

.selectable-button {
  background: none;
  border: 1px solid #6244e0;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  padding: 5px 15px;
  color: #6244e0; }
  .selectable-button.active {
    color: white;
    background: #6244e0; }

div.content-area {
  right: 0px;
  position: absolute;
  top: 61px;
  left: 70px;
  overflow-y: scroll;
  bottom: 0px;
  overflow-x: hidden; }

div.content-area-no-sidebar {
  right: 0px;
  position: absolute;
  top: 61px;
  left: 0px;
  overflow-y: scroll;
  bottom: 0px;
  overflow-x: hidden; }

div.content-area-dashboard {
  right: 0px;
  position: absolute;
  top: 81px;
  left: 0px;
  overflow-y: scroll;
  bottom: 0px;
  overflow-x: hidden; }

div.content-area-small {
  right: 0px;
  position: absolute;
  top: 0px;
  left: 300px;
  overflow-y: scroll;
  bottom: 0px;
  overflow-x: hidden;
  border-left: #e8e8e8 solid 1px;
  padding: 0px 5%; }

div.content-area-map {
  right: 0px;
  position: absolute;
  top: 0px;
  left: 300px;
  overflow-y: scroll;
  bottom: 0px;
  overflow-x: hidden;
  border-left: #e8e8e8 solid 1px; }

div.content-header {
  margin-top: 30px; }
  div.content-header h1 {
    color: #404040 !important; }

hr.header-divide {
  width: 40%;
  height: 8px;
  background-image: -webkit-linear-gradient(top, #00aeff 0%, #0091ff 100%);
  background-image: linear-gradient(180deg, #00aeff 0%, #0091ff 100%);
  border: 0;
  height: 1px; }

.ui.header .sub.header {
  color: #868C8F; }

.activeOrg {
  color: #21ba45 !important; }

.inactiveOrg {
  color: #db2828 !important; }

div.map-info-box {
  z-index: 10;
  position: absolute;
  width: 300px;
  background-color: white;
  left: 10px;
  bottom: 74px;
  border-radius: 5px;
  padding: 15px; }
  div.map-info-box button.dismiss-button {
    border: none;
    padding: 0;
    float: right;
    background: none;
    cursor: pointer; }
    div.map-info-box button.dismiss-button i {
      font-size: 18px;
      padding: 0;
      text-align: right; }
  div.map-info-box h4.info-box-heading {
    font-size: 20px;
    margin-top: 0; }

.infoboxControls {
  border-top: 1px solid #000;
  margin-top: 0px !important; }

.infoboxLocationDetails {
  padding-bottom: 10px; }

button.mapButtonLight {
  position: absolute;
  bottom: 24px;
  right: 60px;
  padding: 11px 20px 9px 20px;
  background: #FFF;
  color: #565656;
  font-size: 18px;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  cursor: pointer; }

button.mapButtonDark {
  position: absolute;
  bottom: 24px;
  right: 60px;
  padding: 11px 20px 9px 20px;
  background: #292929;
  color: #FFF;
  font-size: 18px;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  cursor: pointer; }

button.mapButtonReset {
  position: absolute;
  bottom: 72px;
  right: 60px;
  padding: 11px 20px 9px 20px;
  background: #FFF;
  color: #565656;
  font-size: 18px;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  cursor: pointer; }

button.mapButtonPoi {
  position: absolute;
  bottom: 24px;
  right: 280px;
  padding: 11px 20px 9px 20px;
  background: #FFF;
  color: #565656;
  font-size: 18px;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  cursor: pointer; }

button.mapButtonTraffic {
  position: absolute;
  bottom: 24px;
  right: 185px;
  padding: 11px 20px 9px 20px;
  background: #FFF;
  color: #565656;
  font-size: 18px;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  cursor: pointer; }

/* INTEL MAP BUTTONS */
button.mapButtonLightIntel {
  position: relative;
  float: right;
  margin-right: 10px;
  padding: 11px 20px 9px 20px;
  background: #FFF;
  color: #565656;
  font-size: 18px;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  cursor: pointer; }

button.mapButtonDarkIntel {
  position: relative;
  float: right;
  margin-right: 10px;
  padding: 11px 20px 9px 20px;
  background: #292929;
  color: #FFF;
  font-size: 18px;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  cursor: pointer; }

button.mapButtonPoiIntel {
  position: relative;
  float: right;
  margin-right: 10px;
  padding: 11px 20px 9px 20px;
  background: #FFF;
  color: #565656;
  font-size: 18px;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  cursor: pointer; }

button.mapButtonTrafficIntel {
  position: relative;
  float: right;
  margin-right: 10px;
  padding: 11px 20px 9px 20px;
  background: #FFF;
  color: #565656;
  font-size: 18px;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  cursor: pointer; }

.intelMapButtonContainer {
  margin-top: -64px;
  padding-right: 100px; }

.last_updated_map_time {
  position: absolute;
  bottom: 24px;
  margin-left: 10px;
  padding: 11px 20px 9px 20px;
  background: #FFF;
  color: #565656;
  font-size: 18px;
  box-shadow: none;
  border: none;
  border-radius: 3px; }

.last_updated_map_time_intel {
  position: relative;
  float: left;
  margin-left: 10px;
  padding: 11px 20px 9px 20px;
  background: #FFF;
  color: #565656;
  font-size: 18px;
  box-shadow: none;
  border: none;
  border-radius: 3px; }

.mapContainer {
  width: 100%;
  height: 500px;
  margin: 50px 0px; }

.intelMapContainer {
  width: 100%;
  height: 70vh;
  margin: 50px 0px;
  clear: both; }

div.alert-info-box {
  z-index: 10;
  position: absolute;
  width: 300px;
  background-color: white;
  right: 250px;
  top: 200px;
  border-radius: 5px;
  padding: 15px; }
  div.alert-info-box p {
    font-size: 12px; }
  div.alert-info-box button.dismiss-button {
    border: none;
    padding: 0;
    float: right;
    background: none;
    cursor: pointer; }
    div.alert-info-box button.dismiss-button i {
      font-size: 18px;
      padding: 0;
      text-align: right; }
  div.alert-info-box h4.alert-info-box-heading {
    font-size: 16px;
    margin-top: 0; }

.feature-list-item {
  padding: 3px 3px 0px 3px;
  cursor: pointer;
  border-radius: 3px;
  color: #00aeff;
  display: flex;
  justify-content: space-between; }
  .feature-list-item:hover {
    background-color: #f4f4f4; }
  .feature-list-item.selected-feature {
    border: 1px solid #00b5ad; }
  .feature-list-item .selected-feature-check {
    align-self: center; }
    .feature-list-item .selected-feature-check.hidden-checkmark {
      visibility: hidden; }

.selected-features-text {
  color: #00aeff; }

.feature-list-item .header {
  font-size: 14px; }

.feature-list-item .description {
  font-size: 12px; }

.divider {
  padding-top: 20px; }

.divider .sub.header {
  font-size: 12px; }

.incident-section-fixed-height {
  height: 300px;
  overflow-x: hidden; }
  .incident-section-fixed-height.scrolly {
    overflow-y: scroll; }

.incident-widget-input {
  position: absolute !important;
  bottom: 0px;
  left: 15px;
  right: 10%; }

.incident-section-fixed-height-small {
  height: 200px; }

.incident-task-list {
  padding: 0; }
  .incident-task-list .incident-task-item {
    margin-bottom: 20px;
    padding-left: 5px; }
    .incident-task-list .incident-task-item.priority-low {
      border-left: 10px solid #e8e8e8; }
    .incident-task-list .incident-task-item.priority-medium {
      border-left: 10px solid #ff8623; }
    .incident-task-list .incident-task-item.priority-high {
      border-left: 10px solid #ff5669; }
    .incident-task-list .incident-task-item .item-info {
      float: left;
      width: 90%; }
      .incident-task-list .incident-task-item .item-info :hover {
        cursor: pointer; }
      .incident-task-list .incident-task-item .item-info .incident-task-item-title {
        margin-bottom: 0;
        font-weight: 500; }
        .incident-task-list .incident-task-item .item-info .incident-task-item-title.completed {
          color: #868C8F; }
      .incident-task-list .incident-task-item .item-info .task-info {
        margin-bottom: 0;
        font-size: 12px; }
        .incident-task-list .incident-task-item .item-info .task-info .user-link {
          color: #0382af; }
      .incident-task-list .incident-task-item .item-info .task-sub-info {
        font-size: 12px; }
    .incident-task-list .incident-task-item .item-checkbox {
      width: 10%;
      float: right;
      text-align: left; }
      .incident-task-list .incident-task-item .item-checkbox .ui.checkbox .box::before,
      .incident-task-list .incident-task-item .item-checkbox .ui.checkbox label::before {
        border: 1px solid #00b5ad; }
      .incident-task-list .incident-task-item .item-checkbox .ui.checkbox input:checked ~ .box::after,
      .incident-task-list .incident-task-item .item-checkbox .ui.checkbox input:checked ~ label::after {
        color: white;
        background-color: #00b5ad;
        border-radius: .2rem; }
    .incident-task-list .incident-task-item .incident-task-item-divide {
      background: #e8e8e8;
      border: 0;
      height: 1px; }

.incident-events {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .incident-events .event-box {
    flex-grow: 1;
    overflow-y: scroll; }
    .incident-events .event-box ol {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      padding-bottom: 20px; }
      .incident-events .event-box ol .log-item .log-info {
        font-size: 13px;
        font-family: monospace; }
        .incident-events .event-box ol .log-item .log-info .log-timestamp {
          font-weight: bold; }

.incident-users {
  height: 100%; }
  .incident-users .users-box {
    overflow-y: scroll; }
    .incident-users .users-box ol {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 20px; }
      .incident-users .users-box ol .user-item {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row; }
        .incident-users .users-box ol .user-item .icon-container {
          display: flex;
          align-items: center; }
          .incident-users .users-box ol .user-item .icon-container .user-icon {
            margin-right: 10px; }
            .incident-users .users-box ol .user-item .icon-container .user-icon.Present {
              color: #42c17a; }
            .incident-users .users-box ol .user-item .icon-container .user-icon.Accepted {
              color: #8B8F9A; }
            .incident-users .users-box ol .user-item .icon-container .user-icon.Pending {
              color: #ff8623; }
            .incident-users .users-box ol .user-item .icon-container .user-icon.Declined {
              color: #ff5669; }
        .incident-users .users-box ol .user-item .user-info-container .username {
          margin-bottom: 0; }
        .incident-users .users-box ol .user-item .user-info-container .user-info {
          font-size: 12px; }

.incident-chat {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .incident-chat .chat-box {
    flex-grow: 1;
    overflow-y: scroll; }
    .incident-chat .chat-box ol {
      list-style: none;
      padding: 0px 10px 20px 0px;
      display: flex;
      flex-direction: column-reverse;
      width: 100%; }
      .incident-chat .chat-box ol .message-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px; }
        .incident-chat .chat-box ol .message-item .message-info {
          font-size: 11px;
          color: #c1c1c1;
          margin-bottom: 0; }
        .incident-chat .chat-box ol .message-item .message-content {
          padding: 8px 15px;
          margin-top: 5px;
          display: inline-block; }
        .incident-chat .chat-box ol .message-item.outbound {
          align-self: flex-end; }
          .incident-chat .chat-box ol .message-item.outbound .message-content {
            color: white;
            background-color: #ff8623;
            border-radius: 8px 8px 0 8px; }
          .incident-chat .chat-box ol .message-item.outbound .message-info {
            align-self: flex-end; }
        .incident-chat .chat-box ol .message-item.inbound {
          align-self: flex-start; }
          .incident-chat .chat-box ol .message-item.inbound .message-content {
            background-color: #eae9ee;
            border-radius: 8px 8px 8px 0px; }

.incidentTitle {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
  float: left;
  cursor: pointer; }

.incidentAction {
  color: #00aeff;
  cursor: pointer;
  float: right;
  margin-bottom: 0; }

.incidentSubtitle {
  color: #868C8F; }

.incidentCommsModalTab {
  border: none !important;
  box-shadow: none !important; }

/* FULL PAGE VIEW STYLES */
.incidentWidgetFullScreen {
  position: fixed;
  z-index: 999;
  top: 61px;
  left: 70px;
  background: #FFF;
  width: calc(100vw - 100px);
  height: calc(100vh - 85px);
  padding-top: 20px; }

.incidentWidgetFullScreen .incident-section-fixed-height-small, .incidentWidgetFullScreen .incident-section-fixed-height {
  height: calc(100vh - 220px); }

.incident-header {
  margin-top: 36px; }
  .incident-header .incident-title {
    margin-bottom: 0; }
    .incident-header .incident-title .incident-status-active {
      color: #ff5669; }
    .incident-header .incident-title .incident-status-inactive {
      color: #73797c; }
  .incident-header .incident-info {
    font-weight: 300;
    margin-bottom: 0; }
    .incident-header .incident-info i {
      color: #00b5ad; }
    .incident-header .incident-info .profile-link {
      color: #0382af; }
  .incident-header .incident-info-closed {
    font-weight: 300; }
    .incident-header .incident-info-closed i {
      color: #ff5669; }
    .incident-header .incident-info-closed .profile-link {
      color: #0382af; }
    .incident-header .incident-info-closed .time-since {
      color: #ff5669; }

.user-search-results {
  height: calc(100vh - 100px) !important;
  margin-left: 250px !important;
  z-index: 10 !important;
  position: absolute !important;
  width: 350px;
  background-color: white;
  left: 10px;
  border-radius: 5px;
  padding: 15px;
  float: left !important;
  top: 10px !important;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5 !important;
  overflow: hidden; }
  .user-search-results button.dismiss-button {
    border: none;
    padding: 0;
    float: right;
    background: none;
    cursor: pointer; }
    .user-search-results button.dismiss-button i {
      font-size: 18px;
      padding: 0;
      text-align: right; }
  .user-search-results h4.results-box-heading {
    font-size: 20px;
    margin-top: 0; }
  .user-search-results .item:hover {
    background: #eaeaea;
    cursor: pointer; }

.userSearchList {
  height: calc(100vh - 280px) !important;
  overflow-x: hidden;
  overflow-y: scroll; }

.pagination {
  position: "fixed";
  overflow-x: clip;
  text-align: center; }

.subCategoriesAccordionCont {
  width: 100%;
  padding: 0px;
  margin: 0px;
  margin-top: 10px; }

.topCategorySelect label {
  font-weight: 600; }

.topCategorySelectRow {
  cursor: pointer;
  border-bottom: 1px solid #CCC; }

.alertInfoboxDismiss {
  cursor: pointer;
  float: right; }

.alertInfoboxDrag {
  cursor: drag;
  float: right;
  padding-right: 20px; }

.alertInfoboxDismiss:hover {
  opacity: 0.7; }

.pdfSelect {
  float: right; }

.pdfDropdown {
  width: 200px !important; }

.pdfTitle {
  line-height: 38px; }

a {
  cursor: pointer; }

.si24Popup {
  position: absolute !important;
  right: 5px;
  bottom: 5px; }

.ui.table tr td {
  vertical-align: top; }

.ui.table {
  margin-bottom: 25px !important; }

.clear {
  clear: both; }

.bot20 {
  margin-bottom: 20px; }

.detailsGrid .row {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.changePasswordModalContent .input, .changePasswordModalContent .input input {
  width: 100% !important; }

.profileDetails .ui.grid > .column:not(.row) {
  padding-top: 0px !important; }

.teamName {
  cursor: pointer; }

.teamName:hover {
  color: #00aeff; }

.no-sites,
.no-geofences,
.no-selected-users {
  text-align: center;
  font-size: 11px;
  padding: 5px;
  color: #c1c1c1; }

.selectedListTab {
  margin: 0px !important;
  box-shadow: none !important;
  padding: 0px !important;
  border: none !important; }

.not-found-header {
  size: 28px;
  text-align: center;
  color: #404040; }

.not-found-body {
  color: #73797C;
  text-align: center; }

.not-found-link {
  color: #00aeff; }
  .not-found-link p {
    text-align: center; }

.toast-container {
  bottom: 24px;
  position: absolute;
  right: 24px;
  list-style: none;
  z-index: 9999;
  margin: 0;
  padding: 0; }
  .toast-container .toast {
    display: flex;
    padding: 10px;
    margin-bottom: 16px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65); }
    .toast-container .toast .toast-icon {
      margin-right: 20px; }
    .toast-container .toast .toast-content {
      margin: 0;
      padding-right: 40px;
      flex-grow: 1; }
    .toast-container .toast.info {
      background-color: #e6f7ff;
      border: 1px solid #91d5ff; }
      .toast-container .toast.info .toast-icon {
        color: #1890ff; }
    .toast-container .toast.success {
      background-color: #f6ffed;
      border: 1px solid #b7eb8f; }
      .toast-container .toast.success .toast-icon {
        color: #52c41a; }
    .toast-container .toast.warning {
      background-color: #fffbe6;
      border: 1px solid #ffe58f; }
      .toast-container .toast.warning .toast-icon {
        color: #faad14; }
    .toast-container .toast.error {
      background-color: #fff1f0;
      border: 1px solid #ffa39e; }
      .toast-container .toast.error .toast-icon {
        color: #f5222d; }
    .toast-container .toast .toast-dismiss {
      cursor: pointer;
      margin: 0;
      color: rgba(0, 0, 0, 0.65); }

.ui.search > .ui.input.icon {
  width: 100%; }

.app-layout {
  height: 100%; }

.editableInputRow button {
  background: #FFF !important;
  color: #ef7720 !important;
  display: none !important; }

.editableInputRow:hover button {
  display: inline-block !important; }

.editableInputEditButton {
  padding: 2px !important; }

div.page-container {
  display: flex;
  height: 100%; }

.login-form {
  height: 101.58%;
  background: #868C8F; }

.login-container {
  height: 60%;
  width: 100%;
  padding: 50px !important;
  background: rgba(255, 255, 255, 0.8) !important; }

.login-logo {
  width: 35%;
  margin: -1px auto 50px auto; }

.password-input {
  margin-bottom: 50px !important; }

#loginFailResponse {
  color: #FF0000; }

.travelModal {
  margin: 20px; }

.itineryList {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px; }

.dangerSegment {
  background-color: #ffd2d2; }

.safeSegment {
  background-color: #b3ffb3; }

.unmappedSegment {
  background-color: #fffab3; }

.itineryStatusIcon {
  color: lime; }

.itineryListArrow i {
  display: inline-block;
  vertical-align: middle; }

.travelIcon {
  margin-top: 15px;
  margin-bottom: 15px; }

.travelCountryInput {
  margin-left: 20px; }

tr.active {
  background: #00b5ad !important; }

.comment-block {
  height: 300px;
  overflow: auto; }

